<template>
  <div>
    <v-list dense subheader class="my-0 py-0">
      <v-subheader style="font-size: 14px">
        <v-icon class="mr-2" small>groups</v-icon>
        Parties</v-subheader
      >
      <v-divider></v-divider>
      <v-list-item v-if="booking.customer" style="height: 45px">
        <v-list-item-action class="mr-1">
          <v-avatar v-if="booking.customer.logo" color="white" size="28">
            <v-img :src="booking.customer.logo" contain></v-img>
          </v-avatar>
          <v-avatar v-else color="secondary" size="28">
            <h3>
              {{ booking.customer.name ? booking.customer.name.charAt(0) : "" }}
            </h3>
          </v-avatar>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title style="font-size: 12px">
            {{ booking.customer.name }}
          </v-list-item-title>
          <v-list-item-subtitle style="font-size: 11px">
            Selected Customer
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-row justify="center">
            <v-btn small @click="customerSearchModal = true" icon
              ><v-icon small>edit</v-icon></v-btn
            >
            <v-btn
              small
              @click="booking.customer = undefined"
              color="redPop"
              icon
              ><v-icon small>delete</v-icon></v-btn
            >
          </v-row>
        </v-list-item-action>
      </v-list-item>
      <v-list-item
        v-else
        @click="customerSearchModal = true"
        style="height: 45px"
      >
        <v-list-item-action class="mr-1">
          <v-icon small color="grey">payments</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title style="font-size: 12px"
            >Select Customer</v-list-item-title
          >
        </v-list-item-content>
        <v-list-item-action class="mr-1">
          <v-icon color="primary">add_circle_outline</v-icon>
        </v-list-item-action>
      </v-list-item>

      <div v-if="booking.customer">
        <v-list-item
          :disabled="
            booking.consigneeProfile != undefined ||
            booking.consigneeProfile != null
          "
          style="height: 45px"
        >
          <v-list-item-action class="mr-1">
            <v-icon small color="grey">directions_boat_filled</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title v-if="booking.shipper" style="font-size: 12px">
              {{ booking.shipper.name }}
            </v-list-item-title>
            <v-list-item-title v-else> - </v-list-item-title>
            <v-list-item-subtitle
              v-if="booking.onBehalfShipper"
              style="font-size: 11px"
            >
              On behalf of
              {{ booking.onBehalfShipper.name }}
            </v-list-item-subtitle>
            <v-list-item-subtitle style="font-size: 11px">
              <v-icon x-small class="mr-1">label</v-icon>
              Shipper
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          :disabled="
            booking.consigneeProfile != undefined ||
            booking.consigneeProfile != null
          "
          style="height: 45px"
          @click="(partyModal = true), (partyType = 'Forwarder')"
        >
          <v-list-item-action class="mr-1">
            <v-icon small color="grey">fast_forward</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title v-if="booking.forwarder" style="font-size: 12px">
              {{ booking.forwarder.name }}
            </v-list-item-title>
            <v-list-item-title v-else> - </v-list-item-title>
            <v-list-item-subtitle
              v-if="booking.onBehalfForwarder"
              style="font-size: 11px"
            >
              On behalf of {{ booking.onBehalfForwarder.name }}
            </v-list-item-subtitle>
            <v-list-item-subtitle style="font-size: 11px">
              <v-icon x-small class="mr-1">label</v-icon>
              Forwarder
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          :disabled="
            booking.consigneeProfile != undefined ||
            booking.consigneeProfile != null
          "
          style="height: 45px"
          @click="(partyModal = true), (partyType = 'Consignee')"
        >
          <v-list-item-action class="mr-1">
            <v-icon small color="grey">call_received</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title v-if="booking.consignee" style="font-size: 12px">
              {{ booking.consignee.name }}
            </v-list-item-title>
            <v-list-item-title v-else> - </v-list-item-title>
            <v-list-item-subtitle
              v-if="booking.onBehalfConsignee"
              style="font-size: 11px"
            >
              On behalf of {{ booking.onBehalfConsignee.name }}
            </v-list-item-subtitle>
            <v-list-item-subtitle style="font-size: 11px">
              <v-icon x-small class="mr-1">label</v-icon>
              Consignee
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          :disabled="
            booking.consigneeProfile != undefined ||
            booking.consigneeProfile != null
          "
          style="height: 45px"
          @click="(partyModal = true), (partyType = 'Buyer')"
        >
          <v-list-item-action class="mr-1">
            <v-icon small color="grey">shopping_cart</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title v-if="booking.buyer" style="font-size: 12px">
              {{ booking.buyer.name }}
            </v-list-item-title>
            <v-list-item-title v-else> - </v-list-item-title>
            <v-list-item-subtitle
              v-if="booking.onBehalfBuyer"
              style="font-size: 11px"
            >
              On behalf of {{ booking.onBehalfBuyer.name }}
            </v-list-item-subtitle>
            <v-list-item-subtitle style="font-size: 11px">
              <v-icon x-small class="mr-1">label</v-icon>
              Buyer
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          @click="(partyModal = true), (partyType = 'Stock Provider')"
          style="height: 45px"
        >
          <v-list-item-action class="mr-1">
            <v-icon small color="grey">fact_check</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title
              v-if="booking.stockProvider"
              style="font-size: 12px"
            >
              {{ booking.stockProvider.name }}
            </v-list-item-title>
            <v-list-item-title v-else> - </v-list-item-title>
            <v-list-item-subtitle style="font-size: 11px">
              <v-icon x-small class="mr-1">label</v-icon>
              Stock Provider
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-subheader style="font-size: 14px">
          <v-icon small class="mr-2">settings_suggest</v-icon>
          Template Configuration
        </v-subheader>
        <v-divider></v-divider>
        <v-list-item
          v-if="booking.customer"
          :key="profileKey"
          style="height: 30px"
        >
          <v-list-item-action class="mr-1">
            <v-icon small color="grey">handshake</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title
              v-if="booking.consigneeProfile"
              style="font-size: 12px"
            >
              {{ booking.consigneeProfile.systemReference }}
            </v-list-item-title>
            <v-list-item-title v-else> - </v-list-item-title>
            <v-list-item-subtitle style="font-size: 11px">
              Shipment Profile
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-row justify="center" v-if="booking.consigneeProfile">
              <v-btn small @click="profileModal = true" icon
                ><v-icon small>edit</v-icon></v-btn
              >
              <v-btn small @click="removeProfile()" color="redPop" icon
                ><v-icon small>delete</v-icon></v-btn
              >
            </v-row>
            <v-btn
              icon
              color="primary"
              small
              v-else
              @click="profileModal = true"
            >
              <v-icon>add_circle_outline</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
        <v-list-item
          v-if="booking.consigneeProfile"
          @click="shipmentProfileConfig = true"
          style="height: 30px"
        >
          <v-list-item-action class="mr-1">
            <v-icon v-if="booking.dealTerm" small color="grey">settings</v-icon>
            <v-icon v-else small color="primary">add_circle_outline</v-icon>
          </v-list-item-action>
          <v-list-item-content v-if="!booking.dealTerm">
            <v-list-item-title style="font-size: 12px"
              >Select Profile Configuration</v-list-item-title
            >
          </v-list-item-content>
          <v-list-item-content v-else>
            <v-list-item-title style="font-size: 12px">
              {{ booking.dealTerm.incoTerm }}
            </v-list-item-title>
            <v-list-item-subtitle style="font-size: 11px">
              Profile Configuration
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          @click="incoTermModal = true"
          v-if="!booking.consigneeProfile"
          style="height: 30px"
        >
          <v-list-item-action class="mr-1">
            <v-icon small color="grey">swap_horiz</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title v-if="booking.incoTerm" style="font-size: 12px">
              {{ booking.incoTerm }}
            </v-list-item-title>
            <v-list-item-title v-else>-</v-list-item-title>
            <v-list-item-subtitle style="font-size: 11px">
              Inco Term
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </div>
    </v-list>
    <v-list dense subheader class="my-0 py-0 mx-0">
      <v-divider></v-divider>
      <v-subheader style="font-size: 14px">
        <v-icon class="mr-2" small>payments</v-icon>
        Payment Terms
        <v-btn class="ml-1" icon @click="addPayment()" color="primary"
          ><v-icon>add_circle_outline</v-icon></v-btn
        >
      </v-subheader>
      <v-divider></v-divider>
      <v-list-item
        v-if="
          booking.bookingPaymentDetails &&
          booking.bookingPaymentDetails.length == 0
        "
      >
        <v-list-item-content class="text-center">
          <span style="color: grey; font-size: 12px"
            >No payment details listed</span
          >
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        v-for="payment in booking.bookingPaymentDetails"
        :key="payment.index"
      >
        <v-list-item-content>
          <v-list-item-title style="font-size: 12px">
            {{ findChargeType(payment.chargeType) }}
          </v-list-item-title>
          <v-list-item-subtitle
            v-if="payment.party"
            class="text-wrap"
            style="font-size: 11px"
          >
            <v-icon small class="mr-1">groups</v-icon> {{ payment.party.name }}
          </v-list-item-subtitle>
          <v-list-item-subtitle class="text-wrap" style="font-size: 11px">
            Term: {{ payment.paymentTerm }}
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-row justify="center">
            <v-btn icon small @click="editPaymentItem(payment)"
              ><v-icon small>edit</v-icon></v-btn
            >
            <v-btn icon small @click="deletePaymentItem(payment)" color="red"
              ><v-icon small>delete</v-icon></v-btn
            >
          </v-row>
        </v-list-item-action>
      </v-list-item>
    </v-list>

    <v-dialog
      v-model="customerSearchModal"
      width="400px"
      :fullscreen="$vuetify.breakpoint.mobile"
      persistent
    >
      <v-card
        style="
          background-color: rgba(0, 0, 0, 0.2) !important;
          background: rgba(0, 0, 0, 0.2) !important;
          box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
          backdrop-filter: blur(10px);
          -webkit-backdrop-filter: blur(10px);
          border: 1px solid rgba(255, 255, 255, 0.1);
          border-radius: 10px;
        "
      >
        <v-card-title style="font-size: 14px">
          Search Customer
          <v-spacer></v-spacer>
          <v-btn icon color="redPop" @click="customerSearchModal = false">
            <v-icon small>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-text-field
            placeholder="Search"
            autofocus
            prepend-inner-icon="search"
            v-model="searchCustomer"
            outlined
            dense
            clearable
            hide-details
          ></v-text-field>
          <v-list style="max-height: 50vh; overflow-y: auto" dense>
            <v-list-item
              v-for="organisation in filterOrganisations"
              :key="organisation.id"
              :disabled="
                organisation.clientStatus == 'ON HOLD' ||
                organisation.clientStatus == 'INACTIVE'
              "
              @click="setCustomer(organisation)"
              style="height: 60px"
            >
              <v-list-item-action>
                <v-avatar
                  size="30"
                  v-if="organisation.relatedOrganisation.logo"
                  color="white"
                >
                  <v-img
                    :src="organisation.relatedOrganisation.logo"
                    contain
                  ></v-img>
                </v-avatar>
                <v-avatar size="30" v-else color="secondary">
                  <h3>
                    {{
                      organisation.relatedOrganisation.name
                        ? organisation.relatedOrganisation.name.charAt(0)
                        : ""
                    }}
                  </h3>
                </v-avatar>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title style="font-size: 12px">
                  {{ organisation.relatedOrganisation.name }}
                </v-list-item-title>
                <v-list-item-subtitle
                  style="font-size: 12px"
                  v-if="
                    organisation.relatedOrganisation.alias &&
                    organisation.relatedOrganisation.alias !=
                      organisation.relatedOrganisation.name
                  "
                >
                  {{ organisation.relatedOrganisation.alias }}
                </v-list-item-subtitle>
                <v-list-item-subtitle
                  v-if="organisation.clientStatus"
                  style="font-size: 12px"
                >
                  <v-chip x-small outlined style="border: none">
                    <v-icon
                      small
                      left
                      :color="getClientStatusColor(organisation.clientStatus)"
                      >fiber_manual_record</v-icon
                    >
                    {{ organisation.clientStatus }}
                  </v-chip>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="filterOrganisations.length == 0">
              <v-list-item-content class="text-center">
                <span style="font-size: 12px; color: grey"
                  >No organisations found</span
                >
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="partyModal"
      width="400px"
      :fullscreen="$vuetify.breakpoint.mobile"
      persistent
    >
      <v-card
        :loading="loadingLinkedOrganisations"
        style="
          background-color: rgba(0, 0, 0, 0.2) !important;
          background: rgba(0, 0, 0, 0.2) !important;
          box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
          backdrop-filter: blur(10px);
          -webkit-backdrop-filter: blur(10px);
          border: 1px solid rgba(255, 255, 255, 0.1);
          border-radius: 10px;
        "
      >
        <v-toolbar flat color="transparent">
          <v-toolbar-title style="font-size: 16px">
            Manage {{ partyType }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            icon
            color="redPop"
            @click="(partyModal = false), (searchOrganisation = null)"
          >
            <v-icon small>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-text-field
            outlined
            dense
            placeholder="Search"
            prepend-inner-icon="search"
            v-model="searchOrganisation"
            clearable
            hide-details
          ></v-text-field>
          <v-list dense style="max-height: 50vh; overflow-y: auto">
            <v-list-item v-if="filterCustomerOrganisations.length == 0">
              <v-list-item-content class="text-center">
                <span style="font-size: 12px; color: grey"
                  >No organisations found</span
                >
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-for="organisation in filterCustomerOrganisations"
              :key="organisation.id"
              style="height: 45px"
              @click="setParty(organisation.relatedOrganisation)"
            >
              <v-list-item-action>
                <v-avatar
                  v-if="organisation.relatedOrganisation.logo"
                  color="white"
                  size="30"
                >
                  <v-img
                    :src="organisation.relatedOrganisation.logo"
                    contain
                  ></v-img>
                </v-avatar>
                <v-avatar v-else color="secondary" size="30">
                  <h3 style="color: white">
                    {{
                      organisation.relatedOrganisation.name
                        ? organisation.relatedOrganisation.name.charAt(0)
                        : ""
                    }}
                  </h3>
                </v-avatar>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title style="font-size: 12px">
                  {{ organisation.relatedOrganisation.name }}
                </v-list-item-title>
                <v-list-item-subtitle
                  v-if="organisation.relatedOrganisation.alias"
                  style="font-size: 12px"
                >
                  {{ organisation.relatedOrganisation.alias }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="profileModal"
      :width="!settingShipmentProfile ? '90vw' : '400px'"
    >
      <SearchShipmentProfiles
        v-if="!settingShipmentProfile"
        :customerId="booking.customerId"
        @close="profileModal = false"
        @selected="setShipmentProfile"
      />
      <v-container style="width: 100%; height: 200px" v-else>
        <v-row justify="center" align="center" style="height: 100%">
          <v-col cols="12" class="text-center">
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
            <div class="mt-3">Setting Shipment Profile...</div>
          </v-col>
        </v-row>
      </v-container>
    </v-dialog>

    <v-dialog
      v-model="incoTermModal"
      width="400px"
      :fullscreen="$vuetify.breakpoint.mobile"
      peristent
    >
      <v-card
        style="
          background-color: rgba(0, 0, 0, 0.2) !important;
          background: rgba(0, 0, 0, 0.2) !important;
          box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
          backdrop-filter: blur(10px);
          -webkit-backdrop-filter: blur(10px);
          border: 1px solid rgba(255, 255, 255, 0.1);
          border-radius: 10px;
        "
      >
        <v-toolbar flat color="transparent">
          <v-toolbar-title style="font-size: 16px">
            Set Inco Term
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon color="red" @click="incoTermModal = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-list dense style="max-height: 50vh; overflow-y: auto">
            <v-list-item
              v-for="code in incoTerms"
              :key="code.id"
              @click="setIncoTerm(code)"
              style="height: 45px"
            >
              <v-list-item-content>
                <v-list-item-title>
                  {{ code.name }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ code.abbreviation }}
                </v-list-item-subtitle>
                <!-- To show shipping line if available -->
                <v-list-item-subtitle v-if="code.shippingLine">
                  <v-icon small class="mr-2">directions_boat</v-icon>
                  {{ code.shippingLine }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="shipmentProfileConfig"
      width="800px"
      :fullscreen="$vuetify.breakpoint.mobile"
      peristent
    >
      <v-card
        style="
          background-color: rgba(0, 0, 0, 0.2) !important;
          background: rgba(0, 0, 0, 0.2) !important;
          box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
          backdrop-filter: blur(10px);
          -webkit-backdrop-filter: blur(10px);
          border: 1px solid rgba(255, 255, 255, 0.1);
          border-radius: 10px;
        "
      >
        <v-toolbar flat color="transparent">
          <v-toolbar-title style="font-size: 16px">
            Set Profile Configuration
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon color="redPop" @click="shipmentProfileConfig = false">
            <v-icon small>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text v-if="booking.consigneeProfile">
          <v-list dense>
            <v-card
              class="my-2 py-2"
              style="background-color: var(--v-greyRaised-base) !important"
              v-for="term in booking.consigneeProfile.consigneeProfileIncoTerms"
              :key="term.id"
              :disabled="
                booking.contractPartyId &&
                term.contractPartyId !== booking.contractPartyId
              "
              @click="setDealTerm(term)"
            >
              <v-card-text class="my-0 py-0">
                <v-row class="pa-1">
                  <v-col cols="12" md="6">
                    <v-list dense subheader class="my-0 py-0">
                      <v-subheader style="font-size: 14px">Details</v-subheader>
                      <v-divider></v-divider>
                      <v-list-item>
                        <v-list-item-action class="mr-1">
                          <v-avatar
                            size="32"
                            :color="
                              term.contractParty && term.contractParty.logo
                                ? 'white'
                                : 'secondary'
                            "
                          >
                            <v-img
                              v-if="
                                term.contractParty && term.contractParty.logo
                              "
                              :src="term.contractParty.logo"
                              contain
                            />
                            <h3 v-else-if="term.contractParty">
                              {{ term.contractParty.name.charAt(0) }}
                            </h3>
                            <h3 v-else>-</h3>
                          </v-avatar>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title
                            style="text-transform: capitalize; font-size: 14px"
                            v-if="term.contractParty"
                          >
                            <span
                              v-if="term.contractParty.alias"
                              style="font-size: 12px; font-weight: 500"
                              >{{ term.contractParty.alias }}</span
                            >
                            <span
                              v-else
                              style="font-size: 12px; font-weight: 500"
                              >{{ term.contractParty.name }}</span
                            >
                          </v-list-item-title>
                          <v-list-item-title
                            style="text-transform: capitalize; font-size: 14px"
                            v-else
                          >
                            -
                          </v-list-item-title>
                          <v-list-item-subtitle style="font-size: 11px">
                            Contract Owner
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item style="height: 30px">
                        <v-list-item-content>
                          <v-list-item-title
                            style="text-transform: capitalize; font-size: 14px"
                          >
                            {{ term.incoTerm }}
                          </v-list-item-title>
                          <v-list-item-subtitle style="font-size: 11px">
                            INCO Term
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title
                            style="text-transform: capitalize; font-size: 12px"
                            class="text-wrap"
                            v-if="term.comment"
                          >
                            {{ term.comment }}
                          </v-list-item-title>
                          <v-list-item-title
                            style="text-transform: capitalize; font-size: 12px"
                            v-else
                          >
                            -
                          </v-list-item-title>
                          <v-list-item-subtitle style="font-size: 11px">
                            Notes
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-list dense subheader class="my-0 py-0">
                      <v-subheader style="font-size: 14px">Charges</v-subheader>
                      <v-divider></v-divider>
                      <v-list-item>
                        <v-list-item-action class="mr-1">
                          <v-avatar
                            size="32"
                            :color="
                              term.originParty && term.originParty.logo
                                ? 'white'
                                : 'secondary'
                            "
                          >
                            <v-img
                              v-if="term.originParty && term.originParty.logo"
                              :src="term.originParty.logo"
                              contain
                            />
                            <h3 v-else-if="term.originParty">
                              {{ term.originParty.name.charAt(0) }}
                            </h3>
                            <h3 v-else>-</h3>
                          </v-avatar>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title
                            style="text-transform: capitalize; font-size: 14px"
                            v-if="term.originParty"
                          >
                            <span
                              v-if="term.originParty.alias"
                              style="font-size: 12px; font-weight: 500"
                              >{{ term.originParty.alias }}</span
                            >
                            <span
                              v-else
                              style="font-size: 12px; font-weight: 500"
                              >{{ term.originParty.name }}</span
                            >
                          </v-list-item-title>
                          <v-list-item-title
                            style="text-transform: capitalize; font-size: 14px"
                            v-else
                          >
                            -
                          </v-list-item-title>
                          <v-list-item-subtitle style="font-size: 11px">
                            {{ term.originTerm }}
                          </v-list-item-subtitle>
                          <v-list-item-subtitle style="font-size: 11px">
                            Origin Charges
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item>
                        <v-list-item-action class="mr-1">
                          <v-avatar
                            size="32"
                            :color="
                              term.freightParty && term.freightParty.logo
                                ? 'white'
                                : 'secondary'
                            "
                          >
                            <v-img
                              v-if="term.freightParty && term.freightParty.logo"
                              :src="term.freightParty.logo"
                              contain
                            />
                            <h3 v-else-if="term.freightParty">
                              {{ term.freightParty.name.charAt(0) }}
                            </h3>
                            <h3 v-else>-</h3>
                          </v-avatar>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title
                            style="text-transform: capitalize; font-size: 14px"
                            v-if="term.freightParty"
                          >
                            <span
                              v-if="term.freightParty.alias"
                              style="font-size: 12px; font-weight: 500"
                              >{{ term.freightParty.alias }}</span
                            >
                            <span
                              v-else
                              style="font-size: 12px; font-weight: 500"
                              >{{ term.freightParty.name }}</span
                            >
                          </v-list-item-title>
                          <v-list-item-title
                            style="text-transform: capitalize; font-size: 14px"
                            v-else
                          >
                            -
                          </v-list-item-title>
                          <v-list-item-subtitle style="font-size: 11px">
                            {{ term.paymentTerm }}
                          </v-list-item-subtitle>
                          <v-list-item-subtitle style="font-size: 11px">
                            Freight Charges
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item>
                        <v-list-item-action class="mr-1">
                          <v-avatar
                            size="32"
                            :color="
                              term.destinationParty &&
                              term.destinationParty.logo
                                ? 'white'
                                : 'secondary'
                            "
                          >
                            <v-img
                              v-if="
                                term.destinationParty &&
                                term.destinationParty.logo
                              "
                              :src="term.destinationParty.logo"
                              contain
                            />
                            <h3 v-else-if="term.destinationParty">
                              {{ term.destinationParty.name.charAt(0) }}
                            </h3>
                            <h3 v-else>-</h3>
                          </v-avatar>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title
                            style="text-transform: capitalize; font-size: 14px"
                            v-if="term.destinationParty"
                          >
                            <span
                              v-if="term.destinationParty.alias"
                              style="font-size: 12px; font-weight: 500"
                              >{{ term.destinationParty.alias }}</span
                            >
                            <span
                              v-else
                              style="font-size: 12px; font-weight: 500"
                              >{{ term.destinationParty.name }}</span
                            >
                          </v-list-item-title>
                          <v-list-item-title
                            style="text-transform: capitalize; font-size: 14px"
                            v-else
                          >
                            -
                          </v-list-item-title>
                          <v-list-item-subtitle style="font-size: 11px">
                            {{ term.destinationTerm }}
                          </v-list-item-subtitle>
                          <v-list-item-subtitle style="font-size: 11px">
                            Destination Charges
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="paymentModal"
      persistent
      width="400px"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card
        style="
          background-color: rgba(0, 0, 0, 0.2) !important;
          background: rgba(0, 0, 0, 0.2) !important;
          box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
          backdrop-filter: blur(10px);
          -webkit-backdrop-filter: blur(10px);
          border: 1px solid rgba(255, 255, 255, 0.1);
          border-radius: 10px;
        "
      >
        <v-toolbar flat color="transparent">
          <v-toolbar-title style="font-size: 16px"
            >Payment Details</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            icon
            :disabled="
              !paymentItem.chargeType ||
              !paymentItem.paymentTerm ||
              !paymentItem.partyId
            "
            @click="savePaymentItem"
            ><v-icon small>save</v-icon></v-btn
          >
          <v-btn
            icon
            color="redPop"
            @click="(paymentModal = false), (paymentItem = {})"
          >
            <v-icon small>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-select
            v-model="paymentItem.chargeType"
            outlined
            dense
            label="Charge Type*"
            item-text="value"
            item-value="key"
            :items="paymentTypes"
          ></v-select>
          <v-select
            v-model="paymentItem.paymentTerm"
            outlined
            dense
            label="Payment Term*"
            item-text="value"
            item-value="key"
            :items="paymentTerms"
            hide-details
          ></v-select>
          <!-- <v-select
            v-model="paymentItem.paymentParty"
            outlined
            dense
            label="Payer*"
            :items="availableParties"
          ></v-select> -->
          <v-list-item @click="payerModal = true">
            <v-list-item-action class="mr-2">
              <v-icon small>groups</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title v-if="paymentItem.party" style="font-size: 12px">
                {{ paymentItem.party.name }}
              </v-list-item-title>
               <v-list-item-title v-else>
               -
              </v-list-item-title>
              <v-list-item-subtitle style="font-size: 11px">
                Payment Party
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-autocomplete
            hide-no-data
            dense
            class="mt-2"
            outlined
            clearable
            :loading="paymentTownLoading"
            :search-input.sync="searchTowns"
            v-model="paymentItem.paymentLocation"
            :menu-props="{ closeOnContentClick: true }"
            label="Payment Location"
            :items="paymentTowns"
            item-text="name"
            item-value="locode"
            :filter="filterObject"
          >
            <template v-slot:selection="data">
              <span style="font-size: 13px"
                >{{ data.item.name }} ({{ data.item.locode }})</span
              >
            </template>
            <template v-slot:item="data">
              <v-list-item-content>
                <v-list-item-title>{{ data.item.name }}</v-list-item-title>
                <v-list-item-subtitle>
                  {{ data.item.locode }}</v-list-item-subtitle
                >
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="payerModal" width="500px" persistent>
      <v-card>
        <v-toolbar flat dense>
          <v-toolbar-title style="font-size: 16px"> Payment Party </v-toolbar-title>
          <v-spacer />
          <v-btn icon color="redPop" @click="payerModal = false">
            <v-icon small>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-text-field
            prepend-inner-icon="search"
            placeholder="Search"
            v-model="searchPayer"
            outlined
            dense
            autofocus
            hide-details
            clearable
          ></v-text-field>
          <v-list dense subheader style="max-height: 400px; overflow-y: auto">
            <div v-for="(item, index) in availableContractParties" :key="index">

<div v-if="item.type == 'SUBHEADER'">
  <v-divider></v-divider>
  <v-subheader style="font-size: 14px">
    {{ item.name }}
  </v-subheader>
  <v-divider></v-divider>
</div>
<v-list-item
  v-else
  @click="setPaymentParty(item)"
>
  <v-list-item-action>
    <v-avatar
      :color="item.logo ? 'white' : 'secondary'"
      size="32"
    >
      <v-img v-if="item.logo" :src="item.logo" contain />
      <h3 v-else>
        {{ item.name.charAt(0) }}
      </h3>
    </v-avatar>
  </v-list-item-action>
  <v-list-item-content>
    <v-list-item-title>{{ item.name }}</v-list-item-title>
    <v-list-item-subtitle>{{ item.type }}</v-list-item-subtitle>
  </v-list-item-content>
</v-list-item>
</div>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
    <script>
import SearchShipmentProfiles from "@/components/SystemComponents/SearchShipmentProfile.vue";

export default {
  props: {
    booking: {
      type: Object,
      required: true,
    },
    organisations: {
      type: Array,
      required: true,
    },
  },
  components: {
    SearchShipmentProfiles,
  },

  data: () => ({
    customerSearchModal: false,
    incoTermModal: false,
    incoTerms: [],
    linkedOrganisations: [],
    loadingLinkedOrganisations: false,
    partyModal: false,
    partyType: null,
    partyTypes: [
      {
        name: "Bank",
        value: "bank",
        allowLink: false,
        icon: "account_balance",
        conditionalFields: ["letterOfCredit"],
      },
      {
        name: "Shipper",
        value: "shipper",
        allowLink: true,
        icon: "directions_boat_filled",
        linkedValue: "onBehalfShipper",
      },
      {
        name: "Forwarder",
        value: "forwarder",
        allowLink: true,
        icon: "fast_forward",
        linkedValue: "onBehalfForwarder",
      },
      {
        name: "Buyer",
        value: "buyer",
        allowLink: true,
        icon: "shopping_cart",
        linkedValue: "onBehalfBuyer",
      },
      {
        name: "Consignee",
        value: "consignee",
        allowLink: true,
        icon: "local_shipping",
        linkedValue: "onBehalfConsignee",
      },
      {
        name: "Courier",
        value: "courierParty",
        allowLink: false,
        icon: "local_post_office",
      },
      {
        name: "Notify",
        value: "firstNotify",
        allowLink: false,
        icon: "notifications",
      },
      {
        name: "2nd Notify Party",
        value: "secondNotify",
        allowLink: false,
        icon: "notifications",
      },
      {
        name: "Customer",
        value: "customer",
      },
      {
        name: "Contract Party",
        value: "contractParty",
      },
    ],
    paymentItem: {},
    paymentModal: false,
    paymentKey: 200,
    paymentTypes: [
      { key: "Additional", value: "Additional Charges" },
      { key: "OceanFreight", value: "Basic Freight" },
      { key: "DestinationHaulage", value: "Destination Haulage Charges" },
      { key: "DestinationTerminalHandling", value: "Destination Port Charges" },
      { key: "OriginTerminalHandling", value: "Origin Haulage Charges" },
      { key: "OriginHaulage", value: "Origin Port Charges" },
    ],
    paymentTerms: [
      { key: "Prepaid", value: "Pre-paid" },
      { key: "Collect", value: "Collect" },
      { key: "PayableElsewhere", value: "Payable Elsewhere" },
    ],
    paymentTownLoading: false,
    paymentTownQuery: null,
    paymentTownTimeout: null,
    paymentTowns: [],
    payerModal: false,
    profileKey: 100,
    profileModal: false,
    savingPaymentItem: false,
    searchCustomer: null,
    searchOrganisation: null,
    searchPayer: null,
    searchTowns: null,
    settingShipmentProfile: false,
    shipmentProfileConfig: false,
  }),
  watch: {
    "booking.customerId": {
      handler: function (val) {
        if (val) {
          this.getLinkedOrganisations(val);
        } else {
          this.linkedOrganisations = [];
        }
      },
      immediate: true,
    },
    searchTowns(val) {
      if (this.paymentTownLoading) {
        clearTimeout(this.paymentTownTimeout);
      }
      if (val && val.length > 2) {
        if (this.paymentTownLoading && this.paymentTownQuery) {
          this.paymentTownQuery.abort();
        }
        this.paymentTownLoading = true;
        this.paymentTownTimeout = setTimeout(async () => {
          this.paymentTownQuery = new AbortController();
          const signal = this.paymentTownQuery.signal;
          this.paymentTowns = await this.$API.searchTowns({
            params: {
              search: val,
            },
            signal,
          });
          this.paymentTownLoading = false;
        }, 250);
      } else {
        clearTimeout(this.paymentTownTimeout);
        this.paymentTownLoading = false;
      }
    },
  },
  computed: {
    availableParties() {
      let keys = ["Shipper", "Forwarder", "Consignee", "Notify Party"];
      return keys;
    },
    availableContractParties() {
      let result = [
        {
          type: "SUBHEADER",
          name: "Profile Parties",
        },
        {
          id: this.$store.state.currentOrg.id,
          name: this.$store.state.currentOrg.name,
          alias: this.$store.state.currentOrg.alias,
          logo: this.$store.state.currentOrg.logo,
          type: 'Current Organisation'
        }
      ];
      for (let party of this.partyTypes) {
        if (this.booking[party.value]) {
          result.push({
            type: party.name,
            name: this.booking[party.value].name,
            id: this.booking[party.value].id,
            friendlyName:
              this.booking[party.value].friendlyName ??
              this.booking[party.value].alias,
            logo: this.booking[party.value].logo,
          });
        }
      }
      result.push({
        type: "SUBHEADER",
        name: "Other Parties",
      });
      let organisations = this.organisations.map(x=>x.relatedOrganisation).filter((x) => !result.map((y) => y.id).includes(x.id))
      organisations = organisations.sort((a,b)=>a.name.localeCompare(b.name))
      result = [
        ...result,
        ...organisations          
      ];
      if (this.searchPayer) {
        result = result.filter(
          (x) =>
            x.type != "SUBHEADER" &&
            (x.name.toLowerCase().includes(this.searchPayer.toLowerCase()) ||
            (x.alias && x.alias.toLowerCase().includes(this.searchPayer.toLowerCase())))
        );
      }
      return result;
    },
    filterCustomerOrganisations() {
      let result = this.linkedOrganisations;
      if (this.booking.customer) {
        let find = result.find(
          (x) => x.relatedOrganisationId == this.booking.customerId
        );
        if (!find) {
          result.push({
            relatedOrganisation: this.booking.customer,
            relatedOrganisationId: this.booking.customerId,
          });
        }
      }
      if (this.searchOrganisation) {
        result = result.filter(
          (organisation) =>
            organisation.relatedOrganisation.name
              .toLowerCase()
              .includes(this.searchOrganisation.toLowerCase()) ||
            (organisation.relatedOrganisation.alias &&
              organisation.relatedOrganisation.alias
                .toLowerCase()
                .includes(this.searchOrganisation.toLowerCase()))
        );
      }
      result = result.sort((a, b) =>
        a.relatedOrganisation.name.localeCompare(b.relatedOrganisation.name)
      );
      return result;
    },
    filterOrganisations() {
      let result = this.organisations ?? [];
      result = result.filter((x) => x.isActive && x.isCustomer);
      let unique = [...new Set(result.map((x) => x.relatedOrganisationId))];
      unique = unique.map((x) =>
        result.find((y) => y.relatedOrganisationId == x)
      );
      let finalResult = [];
      for (let i = 0; i < unique.length; i++) {
        let find = result.find(
          (x) => x.relatedOrganisationId == unique[i].relatedOrganisationId
        );
        if (find) {
          finalResult.push(find);
        }
      }
      if (this.searchCustomer) {
        finalResult = finalResult.filter(
          (organisation) =>
            organisation.relatedOrganisation.name
              .toLowerCase()
              .includes(this.searchCustomer.toLowerCase()) ||
            (organisation.relatedOrganisation.alias &&
              organisation.relatedOrganisation.alias
                .toLowerCase()
                .includes(this.searchCustomer.toLowerCase()))
        );
      }
      finalResult = finalResult.sort((a, b) =>
        a.relatedOrganisation.name.localeCompare(b.relatedOrganisation.name)
      );
      return finalResult;
    },
  },
  created() {
    this.getIncoTerms();
  },
  methods: {
    addPayment() {
      if (!this.booking.bookingPaymentDetails) {
        this.booking.bookingPaymentDetails = [];
      }
      this.paymentItem = {
        index: this.booking.bookingPaymentDetails.length,
      };
      this.paymentModal = true;
    },
    async deletePaymentItem(item, index) {
      await this.$API.updateBookingPayment({
        id: item.id,
        isActive: false,
        isDeleted: true,
      });
      this.booking.bookingPaymentDetails.splice(index, 1);
    },
    editPaymentItem(item) {
      this.paymentItem = item;
      this.paymentModal = true;
    },
    filterObject(item, queryText, itemText) {
      return (
        item.name.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) >
          -1 ||
        item.locode.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) >
          -1
      );
    },
    findChargeType(type) {
      return this.paymentTypes.find((x) => x.key == type).value;
    },
    formatParty(party) {
      switch (party) {
        case "forwarder":
          return "Forwarder";
        case "buyer":
          return "Buyer";
        case "consignee":
          return "Consignee";
        case "firstNotify":
          return "Notify Party";
        case "secondNotify":
          return "Notify Party";
        default:
          return party;
      }
    },
    getClientStatusColor(status) {
      switch (status) {
        case "GOOD STANDING":
          return "green";
        case "INACTIVE":
          return "red";
        case "ON HOLD":
          return "orange";
        case "COD":
          return "blue";
      }
    },
    async getIncoTerms() {
      if (localStorage["incoTerms"]) {
        this.incoTerms = JSON.parse(localStorage["incoTerms"]);
      } else {
        this.incoTerms = await this.$API.getIncoTerms();
        localStorage["incoTerms"] = JSON.stringify(this.incoTerms);
      }
    },
    async getLinkedOrganisations(id) {
      this.loadingLinkedOrganisations = true;
      this.linkedOrganisations = await this.$API.getCustomerRelationships(id);
      this.loadingLinkedOrganisations = false;
    },
    removeProfile() {
      this.booking.consigneeProfile = null;
      this.booking.consigneeProfileId = null;
      this.booking.dealTerm = null;
      this.booking.dealTermId = null;
      this.profileKey++;
      this.$emit("bookingChange");
    },
    async savePaymentItem() {
      this.savingPaymentItem = true;
      this.paymentItem.bookingId = this.booking.id;
      if (this.paymentItem.id) {
        await this.$API.updateBookingPayment(this.paymentItem);
        this.booking.bookingPaymentDetails[find] = this.paymentItem;
      } else {
        let result = await this.$API.createBookingPayment(this.paymentItem);
        result.party = this.paymentItem.party
        this.booking.bookingPaymentDetails.push(result);
      }
      this.paymentKey++;
      this.paymentModal = false;
      this.paymentItem = {};
      this.savingPaymentItem = false;
    },
    async setCustomer(organisation) {
      this.booking.customer = organisation.relatedOrganisation;
      this.booking.customerId = organisation.relatedOrganisationId;
      let parties = [
        "buyer",
        "shipper",
        "consignee",
        "forwarder",
        "firstNotify",
        "secondNotify",
        "courierParty",
      ];
      for (let i = 0; i < parties.length; i++) {
        this.booking[parties[i]] = null;
        this.booking[parties[i] + "Id"] = null;
      }
      for (let i = 0; i < parties.length; i++) {
        if (organisation[parties[i]]) {
          this.booking[parties[i]] = organisation.relatedOrganisation;
          this.booking[parties[i] + "Id"] = organisation.relatedOrganisationId;
        }
      }
      this.getLinkedOrganisations(organisation.relatedOrganisationId);
      this.customerSearchModal = false;
      this.$emit("bookingChange");
    },
    async setDealTerm(term) {
      this.booking.dealTermId = term.id;
      this.booking.dealTerm = term;
      this.booking.incoTerm = term.incoTerm;
      this.shipmentProfileConfig = false;
      this.booking.bookingPaymentDetails = [];
      if (!this.booking.contractPartyId) {
        this.booking.contractPartyId = term.contractPartyId;
        this.booking.contractParty = term.contractParty;
      }
      if (term.contractPartyId || this.booking[term.contractOwner]) {
        this.booking.contractParty =
          term.contractParty ?? this.booking[term.contractOwner];
        this.booking.contractPartyId =
          term.contractPartyId ?? this.booking[term.contractOwner + "Id"];
      }
      await this.$API.removeExistingBookingPaymentDetails(this.booking.id);
      if (this.booking.dealTerm) {
        if (this.booking.dealTerm.freightCharge) {
          this.paymentItem = {
            chargeType: "OceanFreight",
            index: this.booking.bookingPaymentDetails.length,
            paymentTerm: this.booking.dealTerm.paymentTerm,
            paymentParty: this.formatParty(this.booking.dealTerm.freightCharge),
            partyId: this.booking.dealTerm.freightPartyId,
            party: this.booking.dealTerm.freightParty,
          };
          await this.savePaymentItem();
        }

        if (this.booking.dealTerm.originCharge) {
          this.paymentItem = {
            chargeType: "OriginTerminalHandling",
            index: this.booking.bookingPaymentDetails.length,
            paymentTerm: this.booking.dealTerm.originTerm,
            paymentParty: this.formatParty(this.booking.dealTerm.originCharge),
            partyId: this.booking.dealTerm.originPartyId,
            party: this.booking.dealTerm.originParty,
          };
          await this.savePaymentItem();
        }

        if (this.booking.dealTerm.destinationCharge) {
          this.paymentItem = {
            chargeType: "DestinationTerminalHandling",
            index: this.booking.bookingPaymentDetails.length,
            paymentTerm: this.booking.dealTerm.destinationTerm,
            paymentParty: this.formatParty(
              this.booking.dealTerm.destinationCharge
            ),
            partyId: this.booking.dealTerm.destinationPartyId,
            party: this.booking.dealTerm.destinationParty,
          };
          await this.savePaymentItem();
        }
      }
      this.$emit("bookingChange");
    },
    setIncoTerm(term) {
      this.booking.incoTerm = term.abbreviation;
      this.incoTermModal = false;
      this.$emit("bookingChange");
    },
    setPaymentParty(party){
      this.paymentItem.partyId = party.id
      this.paymentItem.party = party
      this.payerModal = false
    },
    async setShipmentProfile(profile) {
      this.settingShipmentProfile = true;
      let profileDetail = await this.$API.getConsigneeProfile(profile.id);
      this.booking.consigneeProfile = profile;
      this.booking.consigneeProfileId = profile.id;
      this.booking.ucrNo = profileDetail.ucrNo;
      let parties = [
        "shipper",
        "consignee",
        "forwarder",
        "buyer",
        "firstNotify",
        "secondNotify",
        "courierParty",
        "onBehalfShipper",
        "onBehalfConsignee",
      ];
      for (let i = 0; i < parties.length; i++) {
        this.booking[parties[i]] = profile[parties[i]];
        this.booking[parties[i] + "Id"] = profile[parties[i] + "Id"];
      }
      this.booking.originCountry = profile.originCountry;
      this.booking.originCountryId = profile.originCountryId;
      this.booking.destinationCountry = profile.destinationCountry;
      this.booking.destinationCountryId = profile.destinationCountryId;
      if (profileDetail.finalDestinationValue) {
        this.booking.finalDestinationValue =
          profileDetail.finalDestinationValue;
        this.booking.finalDestinationCity = profileDetail.finalDestinationCity;
      }

      this.booking.regimeCode = profileDetail.regimeCode
        ? profileDetail.regimeCode
        : this.booking.regimeCode;
      this.booking.regime = profileDetail.regime
        ? profileDetail.regime
        : this.booking.regime;
      this.booking.regimeId = profileDetail.regimeId
        ? profileDetail.regimeId
        : this.booking.regimeId;
      this.booking.caProduct = profileDetail.caProduct
        ? profileDetail.caProduct
        : this.booking.caProduct;
      // this.booking.isPrivateHaulage = profileDetail.isPrivateHaulage;
      // this.booking.isRailage = profileDetail.isRailage;
      // this.booking.isCarrierHaulage = profileDetail.isCarrierHaulage;
      if (profileDetail.consigneeProfileIncoTerms.length == 1) {
        this.setDealTerm(profileDetail.consigneeProfileIncoTerms[0]);
      }
      //   if (!profile.consigneeProfileProducts) {
      //     profile.consigneeProfileProducts =
      //       await this.$API.getConsigneeProfileProducts(profile.id);
      //   }
      if (!profile.allDestinationPorts) {
        let findDest = profile.consigneeProfilePorts.filter(
          (x) => x.type == "discharge"
        );
        if (findDest.length == 1) {
          this.booking.portOfDischargeValue = findDest[0].code;
          this.booking.finalDestinationValue = findDest[0].code;
          this.booking.portOfDischargeCity = findDest[0].name;
          this.booking.finalDestinationCity = findDest[0].name;
        }
      }

      if (!profile.allOriginPorts) {
        let findDest = profile.consigneeProfilePorts.filter(
          (x) => x.type == "loading"
        );
        if (findDest.length == 1) {
          this.booking.portOfLoadValue = findDest[0].code;
          this.booking.portOfLoadCity = findDest[0].name;
        }
      }
      this.profileModal = false;
      this.$emit("bookingChange");
    },
  },
};
</script>